import {
  Center,
  Heading,
  Show,
  Spinner,
  Container,
  Text,
  Divider,
  HStack,
  Box,
  Switch,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import {
  UpdateMerchantAccount,
  FeatureFlags,
} from '@tradeaze-packages/schemas';
import {
  useGetMerchantAccount,
  useGetMerchantFeatureFlags,
  useUpdateMerchantAccount,
  useUpdateMerchantFeatureFlag,
} from '@tradeaze/frontend/hooks';
import {
  MerchantAccountForm,
  TwoColumnLayout,
  Layout,
  Map,
  useFormMapMarkers,
} from '@tradeaze/frontend/ui-web';

export const MerchantPage = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { merchantId } = useParams();

  const {
    data: merchantAccount,
    isLoading,
    error,
  } = useGetMerchantAccount(merchantId);

  const featureFlagsQuery = useGetMerchantFeatureFlags('MERCHANT');

  const merchantFeatureFlags: FeatureFlags =
    merchantAccount?.featureFlags ?? {};

  const { mapMarkers, addMerchantMarker, removeMerchantMarker } =
    useFormMapMarkers();

  const { mutate: updateMerchantAccount, isLoading: updateisLoading } =
    useUpdateMerchantAccount(merchantId, {
      callback: () => setIsEditing(false),
    });

  const { mutate: updateMerchantFeatureFlag, isLoading: isLoadingUpdate } =
    useUpdateMerchantFeatureFlag();

  const handleToggle = (featureFlagId: string, featureFlagName: string) => {
    if (merchantId) {
      updateMerchantFeatureFlag({
        merchantId,
        featureFlagId,
        enabled: !merchantFeatureFlags[featureFlagName as keyof FeatureFlags],
      });
    }
  };

  const handleSaveChanges = async (data: UpdateMerchantAccount) => {
    if (!merchantId) {
      return;
    }
    return updateMerchantAccount({ merchantId, ...data });
  };

  useEffect(() => {
    if (!merchantAccount) {
      return;
    }
    if (merchantAccount?.position) {
      addMerchantMarker({
        id: merchantAccount.merchantId,
        position: merchantAccount?.position,
      });
    } else {
      removeMerchantMarker(merchantAccount?.merchantId);
    }
  }, [addMerchantMarker, removeMerchantMarker, merchantAccount]);

  const handleCancelChanges = () => {
    setIsEditing(false);
  };

  const handleStartEditing = () => {
    setIsEditing(true);
  };

  if (isLoading) {
    return (
      <Center h="60vh">
        <Spinner />
      </Center>
    );
  }

  if (error instanceof Error && (error as any)?.response?.status !== 404) {
    return (
      <Center h="60vh" flexDirection={'column'}>
        <Heading>Error</Heading>
        <Text>{error.message}</Text>
      </Center>
    );
  }

  if (!merchantAccount && !isLoading) {
    return <Navigate to="/create-account" />;
  }

  const AccountComponent = (
    <Container maxW={'container.sm'}>
      <Heading size="md">{merchantAccount.merchantName}</Heading>
      <Divider my={4} />
      <HStack>
        <Text fontWeight={'bold'}>Merchant ID:</Text>
        <Text>{merchantAccount?.merchantId}</Text>
      </HStack>
      <HStack>
        <Text fontWeight={'bold'}>Username:</Text>
        <Text>{merchantAccount?.username}</Text>
      </HStack>
      <HStack>
        <Text fontWeight={'bold'}>Booking Approval Required:</Text>
        <Text>{merchantAccount?.isBookingApprovalRequired ? 'Yes' : 'No'}</Text>
      </HStack>
      {merchantAccount?.bookingApprovers &&
        merchantAccount?.bookingApprovers?.length > 0 && (
          <HStack alignItems="flex-start" mt={2}>
            <Text fontWeight={'bold'}>Booking Approvers:</Text>
            <Box>
              <Text>
                {merchantAccount.bookingApprovers.map(
                  approver => `${approver.firstName} ${approver.lastName} (${approver.email})`
                ).join(', ')}
              </Text>
            </Box>
          </HStack>
        )}
      <Divider my={4} />
      <Text fontWeight={'bold'} mb={3}>
        Feature Flags:
      </Text>
      <Box pl={4}>
        {featureFlagsQuery.isLoading ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          featureFlagsQuery.data?.map((flag) => (
            <HStack
              key={flag.featureFlagId}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Text>{flag.name}:</Text>
              <Switch
                isChecked={
                  merchantFeatureFlags[flag.name as keyof FeatureFlags] ?? false
                }
                onChange={() => handleToggle(flag.featureFlagId, flag.name)}
                isDisabled={isLoadingUpdate}
              />
            </HStack>
          ))
        )}
      </Box>
      <Divider my={4} />
      <MerchantAccountForm
        isCreatingNew={false}
        isLoading={updateisLoading}
        isEditing={isEditing}
        handleCancelChanges={handleCancelChanges}
        handleSaveChanges={handleSaveChanges}
        handleStartEditing={handleStartEditing}
        removeMerchantMarker={removeMerchantMarker}
        handleAddMerchantMarker={addMerchantMarker}
        initialAccountDetails={merchantAccount}
        isAdmin={true}
      />
    </Container>
  );

  return (
    <>
      <Show above="md">
        <TwoColumnLayout
          left={AccountComponent}
          right={<Map markers={mapMarkers} />}
        />
      </Show>
      <Show below="md">
        <Layout>{AccountComponent}</Layout>
      </Show>
    </>
  );
};
